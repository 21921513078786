<template>
  <nuxt-page />
</template>
<script setup lang="ts">
import { definePageMeta } from '@/.nuxt/imports';
import { MenuAuthorityIds } from '@/enums/common.enum';
import { useNoticeStore } from '@/stores/notice.store';

definePageMeta({
  menuAuthId: MenuAuthorityIds.COLLECTION
});

const noticeStore = useNoticeStore();

const { fetchLatestNotice } = noticeStore;

fetchLatestNotice();
</script>
